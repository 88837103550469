import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import DescriptionBoxAboutTechnology from "./../DescriptionBox/DescriptionBoxAboutTechnology";
import DescriptionBoxBrief from "./../DescriptionBox/DescriptionBoxBrief";
import DescriptionBoxGallery from "./../DescriptionBox/DescriptionBoxGallery";
import DescriptionBoxHeader from "./../DescriptionBox/DescriptionBoxHeader";
import DescriptionBoxNavigation from "./../DescriptionBox/DescriptionBoxNavigation";
import DescriptionBoxPerks from "./../DescriptionBox/DescriptionBoxPerks";
import DescriptionBoxTechnicalData from "./../DescriptionBox/DescriptionBoxTechnicalData";
import { producent, perks, technologies, dedicated } from "../solars";


class FullProductCard extends Component {
    render (){      
    const item = this.props.item;
    const index = this.props.index;

    return (
        <Row key={index} id={item.folder} className='content'>
            {<Col lg='12' sm='12'>
                <div  className="description">
                    <DescriptionBoxHeader header={item.type[0]}/>
                    <DescriptionBoxTechnicalData technicalData={item}/>
                    <DescriptionBoxAboutTechnology aboutTechnology={item.technologies}/>
                    <DescriptionBoxPerks/>

                </div>
            </Col>}
            {<Col lg='5' sm='12'>
            <div className='picture'>
                <DescriptionBoxGallery folder={item.folder} imgs={item.imgs} />
                </div> 
            </Col>}
        </Row>
    )} 
}


export default FullProductCard;