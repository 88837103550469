import React, { Component } from "react";
import { Navbar, Nav} from 'react-bootstrap';
import { getClientData } from "./DataModelEngine";


class NavigationBar extends Component {
    constructor () {
        super();
        this.state = {
        }
        this.reportUsage = this.reportUsage.bind(this);
    }
    
  reportUsage(event) {
    window.scrollTo(1,0);
    let view = event.target.name;
    const inquiry = {uid: 'AJS01', view};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc/usagereport', 
        global: 'https://laskowskicreative.no/api/lc/usagereport'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(inquiry)
    })
    this.props.handleChangeView(view);
    }

  render (){
    const basicMenu = [{title: "Hjem", link: 'hjem'}, {title: "Solcelelpaneler", link: 'products'}, {title: "Installasjon", link: 'installasjon'}, {title: "Kontakt oss", link: 'kontakt'}];
    let siteContent = this.props.siteContent ? this.props.siteContent : [];
    siteContent = siteContent.concat(basicMenu);
    return (
        <Navbar bg="dark" variant="dark" expand="lg" id="navigation-menu" collapseOnSelect>
            <Navbar.Brand href="/">				
                <div className='nav-logo'>
                    <img  alt={getClientData('clientCompanyName')} src={getClientData('logotyp')}/>
                    
				</div></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse >
                <Nav className="ml-auto">
                    {siteContent.length > 0 && siteContent.map((item, index)=>(
                        <Nav.Link name={item.link} key={index} href={'#'+item.link} onClick={this.reportUsage}>{item.title}</Nav.Link>                    
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )}
}

export default NavigationBar;