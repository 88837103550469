import React, { Component } from "react";
import { Container } from "react-bootstrap";
import CallPopup from './Popups/CallPopup';
import Cookies from 'universal-cookie';
import CookiesPopup from './Popups/CookiesPopup';
import Hjem from "./Home/Hjem";
import Footer from './Footer';
import Products from './Products';
import NavigationBar from './NavigationBar';
import TiltBackground from "./TiltBackground";
import ContactPanel from './ContactPage/ContactPanel';

//graphic files

//App engine
import { serverConnection } from "./serverRouteEngine";
import { getClientData } from "./DataModelEngine";
import { getSiteContent } from "./siteContentEngine";


import './App.css';
import './circle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.js';
import $ from 'jquery';
import Installation from "./Installation/Installation";
import AboutUs from './ContactPage/AboutUs';
import GodJulPopup from "./GodJul/GodJulPopup";
import SnowFlakes from "./GodJul/SnowFlakes";

class App extends Component {
  constructor () {
	  super();
	  this.state = {
      activeView: {
        hjem: true,
        products: false,
        installasjon: false,
        cookies: false,
        kontakt: false,
        about: false,
			}
    }
    this.handleChangeView = this.handleChangeView.bind(this);
  }

  handleChangeView(id) {
    let activeView = {
      hjem: false,
      products: false,
      installasjon: false,
      cookies: false,
      kontakt: false,
      about: false,
    }
    activeView[id] = true;
    this.setState({
      activeView
    })
  }

  handshake() {
    const inquiry = {uid: getClientData('uid')};
    const json = serverConnection('handshake', 'post', 'json', inquiry);
    if (json && json.length > 0)
    {
      this.setState(
        {
          activatePage: json.handshake
        }
      )
    }
  }

  componentDidMount() {
    var x = 0;
    this.handshake();
    $(document).scroll(function () {
      var y = $(this).scrollTop();
      y = y+600;
      x = x > y ? x : y;

      $('.description, .navigation-dots, .dedicated-section ,.picture, .gallery').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });

      $('.about-us').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).fadeIn();
            $('.kontakt').fadeIn();
        } else {
            $('.kontakt').fadeOut();
            $(this).fadeOut();
        }
      });

      $('.preface').each(function () {
        var t = $(this).parent().offset().top;
        if (x > t) {
            $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });     
    });
  }

  render (){
    const cookies = new Cookies();
    const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
    return (
      <div 
      className="App" 
      style={{display: this.state.activatePage === false ? 'none' : 'inherit'}}
      >
        {

        //GodJulPopup/>
        //<SnowFlakes/>
        }
        <CallPopup closeCallPopup={this.closeCallPopup}/>
        {!cookiesAccepted && <CookiesPopup/>}
        <nav id='navigation-menu'>
          <NavigationBar handleChangeView={this.handleChangeView} />
        </nav>

        <main>
          <Container fluid>
            <div className="watermark" style={{background: 'url('+getClientData('logo') +')'}}/>
            {this.state.activeView.hjem && <Hjem/>}
            {this.state.activeView.installasjon && <Installation/>}
            {this.state.activeView.about && <AboutUs/>}
            {this.state.activeView.products && <Products/>}
            {this.state.activeView.kontakt && <ContactPanel/>}

          </Container>        
        </main>
        <footer>
          <Footer/>
        </footer>
      </div>
    );
  }
}

export default App;
