import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { getClientData } from '../DataModelEngine';
import norge from './norge.svg';


class Installation extends Component {
	render() {
		return (
		<Container fluid>
			<Row className='installation'>
				<Col id="pic" lg='6' sm='12'>
                	<div style={{borderRadius: '2%', backgroundImage: "url("+norge+")", backgroundRepeat: 'no-repeat', backgroundSize: "60%", backgroundPosition: "40% 50%", width: '100%', height: '100%'}}/>
                </Col>
				<Col lg='6' sm='12'>
					<h1>Installasjon</h1>
                     <p>Vi monterer solcellepaneler i Oslo-distriktet og i hele Norge.</p>
					 <div id='installation-button'><p>Spør om tilbud</p></div>
					<div className="description-background"></div>
                </Col>
			</Row>

	
		</Container>
		) 
	}
}

export default Installation;