import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { getClientData } from '../DataModelEngine';
import { getHome } from '../siteContentEngine';


class Hjem extends Component {
    render() {
        const home = getHome();
		return (
		<Container fluid>
			<Row className='hjem'>
                {home.map((paragraph, index)=>
                <>
                    {((index+10) %2 !== 0 && window.innerWidth > 800) && 
                    <>
                        <Col sm='12' lg={{span: 6}}>
                            <h1>{paragraph.caption}</h1>
                            {paragraph.description.map((line, idx)=>
                                <p>{line}</p>
                            )}
                            <div className="description-background"></div>
                        </Col> 
                        <Col lg={{span: 6}} sm='12'>
                            <div style={{borderRadius: '2%', backgroundImage: "url("+paragraph.pictures.foto+")", backgroundRepeat: 'no-repeat', backgroundSize: "128%", backgroundPosition: "40% 50%", width: '100%', height: '100%'}}/>
                        </Col>
                    </>}
                    {((index+10)%2 === 0 && window.innerWidth > 800)  &&
                    <>
                        <Col lg={{span: 6}} sm='12'>
                            <div style={{borderRadius: '2%',backgroundImage: "url("+paragraph.pictures.foto+")", backgroundRepeat: 'no-repeat', backgroundSize: "140%", backgroundPosition: "40% 50%", width: '100%', height: '100%'}}/>
                        </Col>  
                        <Col sm='12' lg={{span: 6}}>
                            <h1>{paragraph.caption}</h1>
                            {paragraph.description.map((line, idx)=>
                                <p>{line}</p>
                            )}
                            <div className="description-background"></div>
                        </Col> 
                    </>
                    }
                    {window.innerWidth < 800  &&
                    <>
                        <Col id="pic" sm='12'>
                            <div  style={{margin: '0 0', borderRadius: '2%',backgroundImage: "url("+paragraph.pictures.foto+")", backgroundRepeat: 'no-repeat', backgroundSize: "140%", backgroundPosition: "40% 50%", width: '100%', height: '100%'}}/>
                        </Col>  
                        <Col sm='12'>
                            <h1>{paragraph.caption}</h1>
                            {paragraph.description.map((line, idx)=>
                                <p>{line}</p>
                            )}
                            <div className="description-background"></div>
                        </Col> 
                    </>
                    }
                </>    
                )}
			</Row>
	
		</Container>
		) 
	}
}

export default Hjem;