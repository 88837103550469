import logo from './solcellepanelsett_logo.svg';
import logotyp from './solcellepanelsett_logotyp.svg';
import typ from './solcellepanelsett_type.svg';
import ajslogo from './ajs_logo.svg';
import './circle.css';

const getClientData = function (type) {
    const clientData = {
        clientName: "Adam",
        clientKey: "jOa3S%0%_Y-F1WGc8B%jb7sB",
        uid: "AJS01",
        clientCompanyTaxNumber: "925 559 199 MVA",
        clientCompanyName: "solcellepanelsett.no",
        clientAddress: "",
        clientDomain: "solcellepanelsett.no",
        clientEmail: "post@solcellepanelsett.no",
        clientPhone: "+4792993309",
        clientFancyPhone: '92 99 33 09',
        instagram: "solcellepanelsett",
        facebook: "solcellepanelsett",
        logo: logo,
        logotyp: logotyp,
        typ: typ,
        ajslogo: ajslogo,
    }
    return clientData[type];
}

export {getClientData};
