import hjem01 from './hjem_01.jpg';
import hjem02 from './hjem_02.jpg';
import hjem03 from './hjem_03.jpg';

const home = [
  {
    link: '',
    pictures: {foto: hjem01 , backgroundPositionX: '50%', backgroundPositionY: '50%'},
    title: 'Hva er solcelle?',
    description: [
    'Det er en teknologi som konverterer sollys til elektrisitet.',
    'Dette fornybare energisystemet består av solcellepaneler.',
    'De absorberer solens stråler, og så blir solenergien omdannet til elektrisitet med konstant spenning ved hjelp av invertere.',
    ],
    caption: 'Hva er solcelle?',
  },
  {
    link: '',
    pictures: {foto: hjem02 , backgroundPositionX: '50%', backgroundPositionY: '50%'},
    title: 'Besparelser takket være solceller',
    description: [
      'En riktig valgt solcelleinstallasjon kan effektivt redusere strømkostnadene. Det er først og fremst en sikring mot økning i energiprisene, noe som er svært sannsynlig.',
      'Produseres strømmen i eget system kan du bruke energien løpende.',
      'I tillegg gir vedlikehold av solcelleanlegg ingen ekstra kostnader og er nærmest vedlikeholdsfritt.',
    ],
    caption: 'Besparelser takket være solceller',
  },
  {
    link: '',
    pictures: {foto: hjem03 , backgroundPositionX: '50%', backgroundPositionY: '50%'},
    title: 'Miljøgevinst',
    description: [
      'For mange som bestemmer seg for å investere i solcelleanlegg er det også svært viktig at fornybar energi oppnås på en økologisk måte.',
      'Solceller produserer ingen avgasser, i motsetning til tradisjonelle kilder som slipper ut mer og mer forurensning.',
      'Dette bidrar til miljøvern.',
    ],
    caption: 'Miljøgevinst',
  },
];

const kontakt = [
];

const contactForm = {
  kontaktMain: {
    no: 'Vi er alltid glade for å svare på spørsmålene dine.'
  },
  kontakt: {
      no: ['navn', 'e-post / tlf.', 'telefon', 'melding']
  },
  kontaktRules: {
       no: 'Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av AJS Proaktiv AS for å kontakte deg og svare på det stilte spørsmålet.'
  },
  thankYouHeader: ['Takk for melding!', 'Vi kontakter deg snart.'],
} ;
const aboutUs = [
  '',
  '',
  '',
  '',
];

const imageGalleries = [
  {
    folderName: [''],
    title: ['', '', '', '',],
    description: ['', '', '', '',],
    photos: [
      [
				{file: '', fill: 110, x: 0, y: 50},
				{file: '', fill: 100, x: 50, y: 50},
				{file: '', fill: 100, x: 50, y: 50},
				{file: '', fill: 120, x: 70, y: 50},
      ],
    ],
  },
  {
    folderName: ['parkett'],
    title: ['', '', '', '',],
    description: ['', '', '', '',],
    photos: [
      [
        {file: '', fill: 110, x: 0, y: 50},
        {file: '', fill: 100, x: 50, y: 50},
        {file: '', fill: 100, x: 50, y: 50},
        {file: '', fill: 120, x: 70, y: 50},
      ],
    ],
  },   
];

const getHome = function () {
  return home;
}
const getAboutUs = function () {
  return aboutUs;
}
const getGallery = function (galleryNumber) {
  return imageGalleries[galleryNumber];
}

export {getHome, getGallery, getAboutUs, contactForm};