import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import ring from './ring.svg';
import envelope from './envelope.svg';
import ContactForm from './ContactForm';
import { getClientData } from "../DataModelEngine";
import { serverConnection } from "../serverRouteEngine";
import { contactForm } from "../siteContentEngine";

class ContactPanel extends Component {
    constructor () {
        super();
        this.state = {
            messageId: false,
            messageForm: {
                email: false,
                mobile: false,
                name: false,
                message: false,
                accept: false,
            }
        }
        this.saveValues = this.saveValues.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.clearMessageId = this.clearMessageId.bind(this);
    }
    saveValues(object) {
        this.setState({
            messageForm: {
                ...this.state.messageForm,
                ...object,
            }
        })
    }

    clearMessageId(){
        this.setState({
            messageId: false
        })
    }

    sendForm() {
        let inquiry = this.state.messageForm;
        inquiry.uid = getClientData('uid');
        inquiry.client_key = getClientData('clientKey');
        const json = serverConnection('sendContactForm', 'post', 'json', inquiry);
        this.setState(
            {
                messageId: true, 
                messageForm: {
                    email: false,
                    mobile: false,
                    name: false,
                    message: false,
                    accept: false,
                },
            }
        )
    }
  render (){ 
    const lang = {...contactForm};
    return (
        <Row id="kontakt-panel">
            <Col  lg={{span: '6', order: 'first'}} sm={{span: '12', order: 'first`'}}>

                <h1>Kontakt oss</h1>
                {!this.state.messageId && <ContactForm contactRules={lang.kontaktRules.no} contactForm={lang.kontakt.no} sendForm={this.sendForm} saveValues={this.saveValues} {...this.state.messageForm}/>}
                {this.state.messageId && <div>
                    <h1>{lang.thankYouHeader[0]}</h1>
                    <h3>{lang.thankYouHeader[1]}</h3>
                    <Button ref={(el) => { this.nextButton = el; }} style={{borderRadius: 0, visibility: 'visible'}} as={Col} variant='dark'  name='next' id='next' onClick={this.clearMessageId}>OK</Button>
                </div>}
            </Col>
            {window.innerWidth > 800 && <Col lg={{span: '6'}} sm={{span: '12', order: 'first`'}}>   
                <Row className='justify-content-md-center'>
					<Col sm={12}>
					<h2>{lang.kontaktMain.no}</h2>
					</Col>
					<Col sm={12}lg={{span:4}} style={{textAlign:'center'}}>
                    <a className="contact-data" href={"tel:"+getClientData('clientPhone')}>
                        <div id="contact-data" style={{opacity: 0.8}}>
                        <div style={
                                    {
                                        backgroundImage: 'url('+ring+')',
                                        backgroundRepeat: 'no-repeat',
                                        width: "40%",
                                        height: "40%",
                                        filter: 'invert(1)',
                                        position: "absolute",
                                        top: '45%',
                                        left: '40%',
                                        transform: 'rotateZ(-15deg)',

                                    }
                                }></div>
                            
                            <h1>
                            {getClientData('clientFancyPhone')}
                            </h1>
                        </div>
                        </a>

					</Col>
                    <Col sm={12} lg={{span:4}} style={{textAlign:'center'}}>
                    <a className="contact-data" href={"mailto:"+getClientData('clientEmail')}>
                        <div id="contact-data" style={{opacity: 0.8, backgroundColor: '#00A'}}>
                        <div style={
                                    {
                                        backgroundImage: 'url('+envelope+')',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100%',
                                        width: "40%",
                                        height: "40%",
                                        filter: 'invert(1)',
                                        position: "absolute",
                                        top: '45%',
                                        left: '33%',
                                    }
                                }></div>
                            
                            <h5>
                            <br/>
                            {getClientData('clientEmail')}
                            </h5>
                        </div>
                        </a>

					</Col>
				</Row>

            </Col>}
        </Row>
    )}
}

export default ContactPanel;

