import React, { Component } from "react";
import { producent, perks, technologies, dedicated } from "./../solars";


class DescriptionBoxAboutTechnology extends Component {
  render (){      
    const aboutTechnology = this.props.aboutTechnology;
    const efficiency = this.props.efficiency;
    return (
        <div>
            <div style={{
                backgroundImage: "url(/producent/"+producent.swissSolar['logo']+")",
                height: "30px",
                width: "250px",
                backgroundSize: "100%",
                backgroundRepeat: 'no-repeat',
            }}>
                <a href={producent.swissSolar.link}><div style={{
                height: "30px",
                width: "300px"
            }}></div></a>
            </div>
            <div style={{
                backgroundImage: "url(/technologies/"+technologies[aboutTechnology.name]['logo']+")",
                height: "60px",
                width: "150px",
                backgroundRepeat: 'no-repeat',
                margin: '3vh 0'
            }}/>
            <p>
                {efficiency[0] + '% moduleffektivitet'}
            </p>
            
            {aboutTechnology.description.map((line, idx)=>
            <p>
                {line}
            </p>)
            }
           
        </div>
    )} 
}


export default DescriptionBoxAboutTechnology;