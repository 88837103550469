import React, { Component } from "react";
import { Col } from "react-bootstrap";


class DescriptionBoxNavigation extends Component {
    constructor () {
        super();
        this.state = {
      }
      this.clearHovered = this.clearHovered.bind(this);
      this.selectHovered = this.selectHovered.bind(this);
      this.selectSelected = this.selectSelected.bind(this);
    }
    selectHovered(event){
        const name = event.target.getAttribute('name');
        this.props.hovered(name);
    }

    selectSelected(event){
        const name = event.target.getAttribute('name');
        this.props.selected(name);
    }

    clearHovered(){
        this.props.hovered('');
    }
  render (){   
    const hoveredNow = this.props.hoveredNow;
    const selectedNow = this.props.selectedNow;
    return (
        <>
            <p style={{color: (selectedNow==='brief' && hoveredNow==='') ? '#FFBA00' : hoveredNow==='brief' ? '#FFBA00' : '#FFF'}} name='brief' onClick={this.selectSelected} onMouseOut={this.clearHovered} onMouseOver={this.selectHovered}>.</p>
            {//<p className="description-box-navigation-gallery" style={{color: (selectedNow==='gallery' && hoveredNow==='') ? '#FFBA00' : hoveredNow==='gallery' ? '#FFBA00' : '#FFF'}} name='gallery' onClick={this.selectSelected} onMouseOut={this.clearHovered} onMouseOver={this.selectHovered}>.</p>
            }<p style={{color: (selectedNow==='technicalData' && hoveredNow==='') ? '#FFBA00' : hoveredNow==='technicalData' ? '#FFBA00' : '#FFF'}} name='technicalData' onClick={this.selectSelected} onMouseOut={this.clearHovered} onMouseOver={this.selectHovered}>.</p>
            <p style={{color: (selectedNow==='aboutTechnology' && hoveredNow==='') ? '#FFBA00' : hoveredNow==='aboutTechnology' ? '#FFBA00' : '#FFF'}} name='aboutTechnology' onClick={this.selectSelected} onMouseOut={this.clearHovered} onMouseOver={this.selectHovered}>.</p>
            <p style={{color: (selectedNow==='perks' && hoveredNow==='') ? '#FFBA00' : hoveredNow==='perks' ? '#FFBA00' : '#FFF'}} name='perks' onClick={this.selectSelected} onMouseOut={this.clearHovered} onMouseOver={this.selectHovered}>.</p>
            <p style={{color: (selectedNow==='documents' && hoveredNow==='') ? '#FFBA00' : hoveredNow==='documents' ? '#FFBA00' : '#FFF'}} name='documents' onClick={this.selectSelected} onMouseOut={this.clearHovered} onMouseOver={this.selectHovered}>.</p>
        </>
    )} 
}


export default DescriptionBoxNavigation;