const producent = {
    swissSolar: {name: 'Swiss Solar', logo: 'swiss.png', warranty: 'swiss-30-warranty.svg', link: 'https://www.swissenergy-solar.ch/'},
};

const perks = [
    {description: 'Positive power tolerance (0+5W) guaranteed', logo: '1.svg'},
    {description: 'Lower operation temperature.', logo: '2.svg'},
    {description: 'Hight module conversion efficiency benefit from Passivated Emmiter Rear Contact (PERC) technologydesing with black frame option', logo: '3.svg'},
    {description: 'Aesthetic design. Uniformity appreance, aesthetic reliable. Less hot spot heating risk, make the module more efficiency.', logo: '5.svg'},
    {description: '20 years manufacturer\'s warranty', logo: '6.svg'},
    {description: '30 years linear performance warranty', logo: '4.svg'},
];

const technologies = {
    graphene: {name: 'mwt graphene+', logo: 'graphen-logo.svg'},
    eiger: {name: 'IBEX EIGER', logo: 'eiger-logo.svg'}
};

const dedicated = [
    {description: 'Private rooftop installation', logo: 'privat.svg'},
    {description: 'Commercial and Industrial', logo: 'commercial.svg'},
    {description: 'Ground-mounted solar power plants', logo: 'mounted.svg'},    
];

const documents = [
    {name: 'INSTALLATION AND OPERATION MANUAL FOR IBEX', file: 'SWISS_SOLAR_Instalation_Manual-V2-1', category: 'INSTALLATION MANUAL'},
    {name: 'WARRANTY CONDITIONS', file: 'ENG_WARRANTY_CONDITIONS', category: 'TECHNICAL & WARRANTY'},  
    {name: 'WARRANTY CLAIM FORM', file: 'SWISS-SOLAR-WARRANTY-CLAIM-FORM-1', category: 'TECHNICAL & WARRANTY'},  
    {name: 'CERTIFICATE NO. Z2 110727 0005 REV. 00', file: 'TUV-certificate-Z2-110727-0005', category: 'CERTIFICATES'},    
    {name: 'CERTIFICATE NO. Z2 110727 0004 REV. 00', file: 'TUV-certificate-Z2-110727-0004', category: 'CERTIFICATES'},
    {name: 'CERTIFICATE NO. Z2 110727 0003 REV. 00', file: 'TUV-certificate-Z2-110727-0003', category: 'CERTIFICATES'},
    {name: 'CERTIFICATE NO. Z2 110727 0002 REV. 00', file: 'TUV-certificate-Z2-110727-0002', category: 'CERTIFICATES'},
    {name: 'CERTIFICATE NO. Z2 110727 0001 REV. 00', file: '70.126.20.095.01-00TC_TUV-MARK', category: 'CERTIFICATES'},
    {name: 'SWISS SOLAR CE DECLARATION', file: 'SWISS-SOLAR-CE-Declaration', category: 'CERTIFICATES'},
    {name: 'SWISS SOLAR AG', file: 'TCert-signed_050CE031', category: 'CERTIFICATES'},
    {name: 'ISO 9001', file: 'ISO-9001', category: 'CERTIFICATES'},
    {name: 'ISO 45001', file: 'ISO-45001-SWISS', category: 'CERTIFICATES'},
];

const solars = [
    {
        type: ['IBEX 132MWT-GRAPHENE-400-405', 'half-cell module'],
        powerClasses: [400,405],
        efficiency: [20.7 ,false],
        size: [1889,1035,30],
        weight: 20.5,
        folder: 'IBEX132MWT-GRAPHENE-400-405',
        imgs: [ 'MWT-GRAPHENE-400-405-Angle-768x1172.png', 'MWT-GRAPHENE-400-405-300x547.png', 'IBEX-132MWT-GRAPHENE-400-405-01.svg'],
        dedicated: [1,1,1],
        dataSheet: 'IBEX-132MWT-GRAPHENE-400-405-Outlines_compressed.pdf',
        producent: 'swissSolar',
        recommended: false,
        new: true,
        technologies: {
            name: 'graphene', 
            description: [
                'Cell size (166×83 mm)',
                'Positive power tolerance (0+5W) guaranteed',
                'Full Black: All black design for more elegant applications',
                'Innovative Layout: Innovative back contact module layout with asymmetric design for higher efficiency power',
                'High performance under low light. Works at cloudy, rainy days',
                'High Reliability: conductive back sheet’s 2D encapsulation avoids welding stress and micro crack, resulting lower degradation under multiple harsh testing conditions',
                'Lead Free: Eco-friendly PV design achieves lead-free MWT module without soldering materials',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 108MHC-EiGER-390-410 FULL BLACK', 'half-cell module'],
        powerClasses: [390,410],
        efficiency: [21 ,false],
        size: [1724,1134,30],
        weight: 21.5,
        folder: 'IBEX108MHC-EiGER-390-410-FULL-BLACK',
        imgs: ['IBEX-108MHC-EiGER-FULL-BLACK-Angle-768x1011.png', 'IBEX-108MHC-EiGER-FULL-BLACK-300x450.png', 'IBEX-108MHC-EiGER-390-410-01.svg'],
        dedicated: [1,1,1],
        dataSheet: 'IBEX-108MHC-EiGER-390-410-FULLBLACK-V3_compressed.pdf',
        producent: 'swissSolar',
        recommended: true,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M10 Gallium doped mono wafer',
                'P-type PERC cell',
                'largest cell size (182×91)',
                '9BB Half-cut technology',
                'Color back sheet – black',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 120MHC-EiGER-440-455 FULL BLACK', 'half-cell module'],
        powerClasses: [440,455],
        efficiency: [21.01 ,false],
        size: [1903,1134,30],
        weight: 27,
        folder: 'IBEX120MHC-EiGER-440-455-FULL-BLACK',
        imgs: ['IBEX-120MHC-EiGER-FULL-BLACK-Angle-768x1092.png', 'IBEX-120MHC-EiGER-FULL-BLACK-300x501.png', 'IBEX-120MHC-EIGER-440-455.svg'],
        dedicated: [1,1,1],
        dataSheet: 'IBEX-120MHC-EiGER-440-455-FULLBLACK_compressed.pdf',
        producent: 'swissSolar',
        recommended: true,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M10 Gallium doped mono wafer',
                'P-type PERC cell',
                '9BB Half-cut technology',
                'largest cell size (182×91)',
                'Color back sheet – black',
                'Normal 6 rows 60 C module design',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 132MHC-EiGER-495-500 FULL BLACK', 'half-cell module'],
        powerClasses: [495,500],
        efficiency: [21.06 ,false],
        size: [2094,1134,35],
        weight: 26,
        folder: 'IBEX132MHC-EiGER-495-500-FULL-BLACK',
        imgs: ['IBEX-132MHC-EiGER-595-600-Angle-Black-768x1289.png', 'IBEX-132MHC-EiGER-595-600-Black-300x563.png', 'IBEX-132MHC-EiGER-495-500-BLACK.svg'],
        dedicated: [1,1,1],
        dataSheet: 'ENG-IBEX-132MHC-EiGER-495-500-FULLBLACK-v1_compressed.pdf',
        producent: 'swissSolar',
        recommended: true,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M10 Gallium doped mono wafer',
                'P-type PERC cell',
                '9BB Half-cut technology',
                'largest cell size (182×91)',
                'Color back sheet – black',
                'Normal 6 rows 72 C / 66 C module design',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 144MHC-EiGER-525-545 FULL BLACK', 'half-cell module'],
        powerClasses: [525,545],
        efficiency: [21.09 ,false],
        size: [2279,1134,35],
        weight: 28.5,
        folder: 'IBEX144MHC-EiGER-525-545-FULL-BLACK',
        imgs: ['IBEX-144-EIGER-BLACK-Angle-768x1293.png', 'IBEX-144-EIGER-BLACK-300x607.png', 'IBEX-144MHC-EiGER-525-545-BLACK.svg'],
        dedicated: [0,1,1],
        dataSheet: 'IBEX-144MHC-EiGER-525-545-FULLBLACK_compressed.pdf',
        producent: 'swissSolar',
        recommended: false,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M10 Gallium doped mono wafer',
                'P-type PERC cell',
                '9BB Half-cut technology',
                'largest cell size (182×91)',
                'Color back sheet – black',
                'Normal 6 rows 72 C / 66 C module design',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 156MHC-EiGER-570-590 FULL BLACK', 'half-cell module'],
        powerClasses: [570,590],
        efficiency: [21.09 ,false],
        size: [2465,1134,35],
        weight: 30.5,
        folder: 'IBEX156MHC-EiGER-570-590-FULL-BLACK',
        imgs: ['IBEX-156MHC-EIGER-FULL-BLACK-Angle-768x1345.png', 'IBEX-156MHC-EIGER-BLACK-300x653.png', 'IBEX-156MHC-EiGER-570-590-BLACK.svg'],
        dedicated: [0,1,1],
        dataSheet: 'IBEX-156MHC-EiGER-570-590-FULLBLACK_compressed.pdf',
        producent: 'swissSolar',
        recommended: false,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M10 Gallium doped mono wafer',
                'P-type PERC cell',
                '9BB Half-cut technology',
                'largest cell size (182×91)',
                'Color back sheet – black',
                'Normal 6 rows 78 C module design',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 54M-EiGER-530-550 FULL BLACK', 'half-cell module'],
        powerClasses: [530,550],
        efficiency: [21.48 ,false],
        size: [1965,1303,35],
        weight: 28.5,
        folder: 'IBEX54M-EiGER-530-550-FULL-BLACK',
        imgs: ['IBEX-54M-EiGER-530-550-Black-Angle-1-768x1011.png', 'IBEX-54M-EiGER-530-550_300x550.png', 'IBEX-54M-EiGER-530-550.svg'],
        dedicated: [0,1,1],
        dataSheet: 'IBEX-54M-EiGER-530-550-FULLBLACK_compressed.pdf',
        producent: 'swissSolar',
        recommended: true,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M10 Gallium doped mono wafer',
                'P-type PERC cell',
                '9BB Half-cut technology',
                'Color back sheet – black',
                'largest cell size (210×105)',
                'Normal 6 rows 54 C module design',
                'Power temperature coefficient -0.35%/C',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 60M-EiGER-590-610 FULL BLACK', 'half-cell module'],
        powerClasses: [590,610],
        efficiency: [21.55 ,false],
        size: [2172,1303,35],
        weight: 31,
        folder: 'IBEX60M-EiGER-590-610-FULL-BLACK',
        imgs: ['EiGER-60-Products-vision-768x1092.png', 'IBEX-60M-EiGER-BLACK_300x550.png', 'IBEX-60M-EiGER-590-610.svg'],
        dedicated: [0,1,1],
        dataSheet: 'IBEX-60M-EiGER-590-610-FULLBLACK_compressed.pdf',
        producent: 'swissSolar',
        recommended: false,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M12 Gallium doped mono wafer',
                'P-type PERC cell',
                '9BB Half-cut technology',
                'Color back sheet – black',
                'largest cell size (210×105)',
                'Normal 6 rows 60 C module design',
                'Power temperature coefficient -0.35%/C',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
    {
        type: ['IBEX 66M-EiGER-650-670 FULL BLACK', 'half-cell module'],
        powerClasses: [650,670],
        efficiency: [21.57 ,false],
        size: [2384,1303,35],
        weight: 34.5,
        folder: 'IBEX66M-EiGER-650-670-FULL-BLACK',
        imgs: ['EiGER-66-Products-vision-768x1289.png', 'IBEX-66M-EiGER-650-670_300x550.png', 'IBEX-66M-EiGER-650-670.svg'],
        dedicated: [0,1,1],
        dataSheet: 'IBEX-66M-EiGER-650-670-FULLBLACK_compressed.pdf',
        producent: 'swissSolar',
        recommended: true,
        new: false,
        technologies: {
            name: 'eiger', 
            description: [
                'M12 Gallium doped mono wafer',
                'P-type PERC cell',
                '9BB Half-cut technology',
                'Color back sheet – black',
                'largest cell size (210×105)',
                'Normal 6 rows 66 C module design',
                'Power temperature coefficient -0.35%/C',
            ],
    },
        warranty: [
            'At least 97% of nominal power during first year.',
            'Thereafter max. 0.5086% degradation per year.',
            'At least 86.83% of nominal power up to 20 years.',
            'At least 81.75% of nominal power up to 30 years.',
        ],
        perks: [1,1,1,1,1,1],
    },
]

export {solars, producent, perks, dedicated, documents, technologies};