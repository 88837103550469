import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { producent, perks, technologies, dedicated, documents } from "./../solars";
import TiltBackground from './../TiltBackground';

class DescriptionBoxDocuments extends Component {
    constructor () {
        super();
        this.state = {
        }
    }

    render (){
        const folder = this.props.folder;
        const dataSheet = this.props.dataSheet;

    return (
        <Row id='documents'>
             <>
                <Col lg='6'>
                    <a href={'./solar/'+folder+'/'+ dataSheet}><TiltBackground><img src={'./solar/'+folder+'/data-sheet.jpg'}/></TiltBackground></a>
                    <a href={'./solar/'+folder+'/'+ dataSheet}><p>{'Data sheet (ENG)'}</p></a>
                </Col>
            </>
        {documents.map((doc, index)=>
        <>
            {doc.category !== 'CERTIFICATES' &&
                <Col lg='6'>
                    <a href={'./documents/'+doc.file+'.pdf'}><TiltBackground><img src={'./documents/'+doc.file+'.png'}/></TiltBackground></a>
                    <a href={'./documents/'+doc.file+'.pdf'}><p>{doc.name +  ' (ENG)'}</p></a>
                </Col>
            }
        </>
        )}
        </Row>
    )} 
}


export default DescriptionBoxDocuments;