import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DescriptionBox from "./DescriptionBox/DescriptionBox";
import FullProductCard from "./ProductCard/FullProductCard";
import ImgsGallery from "./ImgsGallery";
import Preface from './Preface';
import { producent, perks, technologies, dedicated, solars } from "./solars";
import TiltBackground from "./TiltBackground";
import { getClientData } from "./DataModelEngine";
import { getSiteContent } from "./siteContentEngine";


class Products extends Component {
    constructor () {
        super();
        this.state = {
            fullCard: ''
      }
      this.fullCardActivate = this.fullCardActivate.bind(this);
    }
          
fullCardActivate(value) {
    this.setState({
        fullCard: value,
    });
    }

  render (){      
    const products = solars;
    const fullCard = this.state.fullCard;
    return (
        <>
            {(products.length > 0 && fullCard === '') && products.map((item, index)=>(    
                <DescriptionBox fullCardActivate={this.fullCardActivate} item={item} index={index}/>
            ))} 
            {fullCard !== '' && <FullProductCard index={fullCard} item={products[fullCard]}/>}
        </>
    )}
}

export default Products;