import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import DescriptionBoxHeader from "./DescriptionBoxHeader";
import { producent, perks, technologies, dedicated } from "./../solars";
import bestseller from './../bestseller.png';

class DescriptionBoxBrief extends Component {
  render (){      
    const technologiesInfo = this.props.technologies;
    const efficiency = this.props.efficiency;
    const powerClasses = this.props.powerClasses;
    const folder = this.props.folder;
    const imgs = this.props.imgs;
    const recommended = this.props.recommended;
    const newProduct = this.props.newProduct;
    return (
        <div>
           {window.innerWidth < 800 && <div style={{backgroundImage: "url(/solar/"+folder+'/'+imgs[0] +")",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '50%',
                height: "350px"}}>
            </div> }
            <div style={{position: 'absolute', top: '30%', left: '70%', right: 0, bottom: 0, backgroundImage: "url("+bestseller+")",
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '30%, 30%',
                backgroundSize: '100%',
                display: recommended ? 'inherit' : 'none',
                }}/>
            <div style={{
                backgroundImage: "url(/technologies/"+technologies[technologiesInfo.name]['logo']+")",
                height: "60px",
                width: "150px",
                backgroundRepeat: 'no-repeat',
                margin: '3vh 0'
            }}/>
            
                <div style={{
                backgroundImage: "url(/technologies/swiss-30-warranty.svg",
                height: "150px",
                width: "100px",
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                top: "25%", 
                left: '76%',
            }}/>
            
            <Row>
                <Col lg='4' sm='4' xs='4'>
                    <h3>Kraftklasser:</h3>
                </Col>
                <Col lg='8' sm='8' xs='8'>   
                    <h3>{' '}{powerClasses[1] ? 'fra ' + powerClasses[0] + ' til ' + powerClasses[1] + ' (Wp)' : 'opptil ' + powerClasses[0] + ' (Wp)'}</h3>
                </Col>
            </Row>
            <Row>
                <Col lg='4' sm='4' xs='4'>
                    <h3>Effektivitet:</h3>
                </Col>
                <Col lg='8' sm='8' xs='8'>
                    <h3>{efficiency[1] ? 'opptil ' + efficiency[0] + '% (opptil ' + efficiency[1] + '% fra baksiden)' : 'opptil ' + efficiency[0] + '%'}</h3>
                </Col>
            </Row>          
            {/*item.technologies.description.map((line, idx)=>
            <p>
                {line}
            </p>)
            */}

        </div>
    )} 
}


export default DescriptionBoxBrief;