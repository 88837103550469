import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { producent, perks, technologies, dedicated } from "./../solars";


class DescriptionBoxPerks extends Component {
  render (){
    return (
        <>
        <Row>
            {perks.map((perk,index)=>
                <>
                    <Col lg='1' md='2' sm='2' xs='2'>
                        <div style={{
                            backgroundImage: "url(/perks/" + perk.logo+")",
                            height: "60px",
                            width: "40px",
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            borderBottom: '1px solid #FFBA00'
                        }}/>
                    </Col>
                    <Col lg='5' md='10' sm='10' xs='10'>
                        <p>{perk.description}</p>
                    </Col>
                </>
            )}
        </Row>
        </>
    )} 
}


export default DescriptionBoxPerks;