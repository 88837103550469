import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { producent, perks, technologies, dedicated } from "./../solars";


class DescriptionBoxDedication extends Component {
    constructor () {
        super();
        this.state = {
            fullDescription: '',

        }
        this.showDescription = this.showDescription.bind(this);
        this.clearDescription = this.clearDescription.bind(this);
    }

    showDescription(event){
        const id = event.target.getAttribute('name');
        this.setState({
            fullDescription: id
        });
    }

    clearDescription(){
        this.setState({
            fullDescription: ''
        });
    }

    render (){ 
    const dedicatedValue = this.props.dedicated;

    return (
    dedicated.map((item, index)=>
        <div key={index}>
            <div 
            name={index} 
            style={{
                visibility: !dedicatedValue[index] ? 'hidden' : 'visible',
                height: '100px',
                width: '100px',
                margin: '0 25%',
                backgroundRepeat: 'no-repeat', 
                backgroundImage: 'url(dedicated/' + item.logo +')'
            }}
            onMouseOver={this.showDescription}
            onMouseOut={this.clearDescription}
            />
            <div style={{
                display: window.innerWidth < 600 ? 'none' : 'inherit'
            }}>
                <p style={{visibility: this.state.fullDescription !== ''+index ? 'hidden' : 'visible'}}>{item.description}</p>
            </div>
        </div>
    )
    )} 
}



export default DescriptionBoxDedication;