import React, { Component } from 'react';
import Lightbox from 'react-images';
import { getClientData } from './DataModelEngine';
import insta from './instagram.svg';

class PicturesGallery extends Component {
    constructor(props){
    super(props);
    this.state = { currentImage: 0, lightboxIsOpen: false}; 
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
      this.nextImage = this.nextImage.bind(this, this.state.currentImage);
      this.previousImage = this.previousImage.bind(this);
    }

  openLightbox(event) {
    let id = parseInt(event.target.id);
    this.setState({
      currentImage: id,
      lightboxIsOpen: true,
    });     
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });    
  }

  previousImage() {
    let currentImage = this.state.currentImage;
    currentImage--;
    this.setState({
      currentImage    
    });     
  }

  nextImage() {
    let currentImage = this.state.currentImage;
    currentImage++;
    this.setState({
      currentImage
    }); 
  }

	render() {
		const caption = this.props.title;
    const folderName = this.props.folderName ? this.props.folderName : 'herringbone';
		const imageArray = this.props.photos ? this.props.photos : new Array();
		const imageArrayRepresentation = imageArray.map((miniature, imageIndex)=>(
            <div
            onClick={this.openLightbox}
            className="miniature"
            id={imageIndex}
            style={{
                backgroundImage: 'url(./'+folderName+'/'+miniature.file+')',
                backgroundSize: miniature.fill +'%',
                backgroundPositionX: miniature.x +'%',
                backgroundPositionY: miniature.y +'%'}}
            key={imageIndex}
            alt={this.props.title}/>
        ))

		return (
			<div>
        <h1>{this.props.title}</h1>
        <div style={{display: 'inline', float: 'left'}}>
            {this.props.description.map((paragraph, pIndex) => (
              <p key={pIndex}>
                {paragraph}
              </p>
            ))}
        </div>
				<div className="thumbnail">
				  {imageArrayRepresentation}
          <div className='gallery-insta'
          style={{backgroundImage: {insta}, backgroundRepeat: 'no-repeat',  backgroundSize: 'cover'}}>
						<a href={'https://instagram.com/' + getClientData('instagram')}><img src={insta} alt={getClientData('clientCompanyName') + " on Instagram"}/><p>@vyvobygg</p></a>
				</div>		
				</div>

        <Lightbox
          images={imageArray.map(function(miniature) {
		        return {src: folderName+'/'+miniature.file, caption: caption}
		      })}
	        currentImage ={this.state.currentImage}
	        isOpen={this.state.lightboxIsOpen}
	        onClickPrev={this.previousImage}
	        onClickNext={this.nextImage}
	        onClose={this.closeLightbox}
	        imageCountSeparator=" / "
	        rightArrowTitle="NEXT"
      		leftArrowTitle="BACK"
      		closeButtonTitle="CLOSE"
      		backdropClosesModal				
		    />
			</div>
			);
	}
}

/*PicturesGallery.propTypes ={
  photos: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  folderName: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string ]),
}*/

export default PicturesGallery;