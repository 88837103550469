import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import insta from './../instagram.svg';
import fb from './../facebook.svg';
import ContactPanel from './ContactPanel';
import { getAboutUs } from '../siteContentEngine';
import { getClientData } from '../DataModelEngine';
class AboutUs extends Component {
	render() {
        let aboutUs = getAboutUs();
        const header = aboutUs[0];
        aboutUs.shift();
		return (
		<Container fluid>
			<Row className='about-us'>
				<Col lg='6' sm='12'>	
                    <h1>{header}</h1>
                    {aboutUs && aboutUs.length > 0 && aboutUs.map((textLine, index)=>(
                        <p key={index}>{textLine}</p>
                    ))}
                    <div className='gallery-insta' style={{backgroundImage: {fb}, backgroundRepeat: 'no-repeat',  backgroundSize: 'cover'}}>
					    <a href={'https://facebook.com/' + getClientData('facebook')}><img src={fb} style={{height: '77px'}} alt={getClientData('clientCompanyName') + " on Facebook"}/><p>follow us</p></a>
				    </div>	
                    <div className='gallery-insta' style={{backgroundImage: {insta}, backgroundRepeat: 'no-repeat',  backgroundSize: 'cover'}}>
					    <a href={'https://instagram.com/' + getClientData('instagram')}><img src={insta} style={{height: '77px'}} alt={getClientData('clientCompanyName') + " on Instagram"}/><p>{'@'+getClientData('instagram')}</p></a>
				    </div>		
                </Col>
			</Row>
	
		</Container>
		) 
	}
}

export default AboutUs;