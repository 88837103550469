import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import bestseller from './../bestseller.png';
import newRibbon from './../new.png';
import { producent, perks, technologies, dedicated } from "./../solars";


class DescriptionBoxGallery extends Component {
  render (){      
    const folder = this.props.folder;
    const imgs = this.props.imgs;
    const recommended = this.props.recommended;
    const newProduct = this.props.newProduct;
    return (
    <>
        <div style={{backgroundImage: "url(/solar/"+folder+'/'+imgs[0] +")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50%'}}>
        </div>  
        <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundImage: "url(/solar/"+folder+'/'+imgs[1] +")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '70%, 30%',
        backgroundSize: '30%'}}>
        </div>  

    </>
    )} 
}


export default DescriptionBoxGallery;