import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

import { producent, perks, technologies, dedicated } from "./../solars";


class DescriptionBoxTechnicalData extends Component {
  render (){      
    const technicalData = this.props.technicalData;
    const powerClasses = technicalData.powerClasses;
    const efficiency = technicalData.efficiency;
    return (
        <>
        <Row>
            <Col lg='3' sm='3' xs='3'>
                <p>Type:</p>
            </Col>
            <Col lg='9' sm='9' xs='9'>
                <p>{technicalData.type[0] + ' ' + technicalData.type[1] }</p>
            </Col>
        </Row>
        <Row>
            <Col lg='3' sm='3' xs='3'>
                <p>Kraftklasser:</p>
            </Col>
            <Col lg='9' sm='9' xs='9'>   
                <p>{' '}{powerClasses[1] ? 'fra ' + powerClasses[0] + ' til ' + powerClasses[1] + ' (Wp)' : 'opptil ' + powerClasses[0] + ' (Wp)'}</p>
            </Col>
        </Row>
        <Row>
            <Col lg='3' sm='3' xs='3'>
                <p>Effektivitet:</p>
            </Col>
            <Col lg='9' sm='9' xs='9'>
                <p>{efficiency[1] ? 'opptil ' + efficiency[0] + '% (opptil ' + efficiency[1] + '% fra baksiden)' : 'opptil ' + efficiency[0] + '%'}</p>
            </Col>
        </Row>
        <Row>
            <Col lg='3' sm='3' xs='3'>
                <p>Dimensjoner:</p>
            </Col>
            <Col lg='9' sm='9' xs='9'>
                <p>{technicalData.size[0] + ' x ' + technicalData.size[1] + ' x ' + technicalData.size[2] + ' (mm)' }</p>
            </Col>
        </Row>
        <Row>
            <Col lg='3' sm='3' xs='3'>
                <p>Vekt:</p>
            </Col>
            <Col lg='9' sm='9' xs='9'>
                <p>{technicalData.weight + ' kg ' }</p>
            </Col>
        </Row>
        <Row>
            <Col lg='3' sm='3' xs='3'>
                <p>Garanti:</p>
            </Col>
            <Col lg='9' sm='9' xs='9'>
                {technicalData.warranty.map((line, index)=>
                <p style={{fontSize: "80%"}}>{line}</p>)}
                
            </Col>
        </Row>
        <div style={{
                backgroundImage: "url(/technologies/swiss-30-warranty.svg",
                height: "150px",
                width: "100px",
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
                top: "65%", 
                left: '80%',
            }}/>
        </>
    )} 
}


export default DescriptionBoxTechnicalData;