import React, { Component } from 'react';
import fb from './facebook.svg';
import insta from './instagram.svg';
import { getClientData } from './DataModelEngine';

class Footer extends Component {
	render() {
		return (
		<div className='footer'>
			<div>
				<div className='footer-logo'>
					<p><img  alt={getClientData('clientCompanyName')} src={getClientData('logotyp')}/></p>
				</div>
				<div className='footer-contact'>		
					<a href={"tel:"+getClientData('clientPhone')}><p>{getClientData('clientFancyPhone')}</p></a>
					<a href={"mailto:"+getClientData('clientEmail')}><p>{getClientData('clientEmail')}</p></a> 
				</div>
				<div className='footer-ajs-logo'>
					<p><img  alt={getClientData('clientCompanyName')} src={getClientData('ajslogo')}/></p>
				</div>
				<p>{getClientData('clientCompanyTaxNumber')}</p>


			</div>
			<div >	
				

			</div>
			<div>
			<div className='social-ico'>
				<div>
					<a href={'https://facebook.com/' + getClientData('facebook')}><img src={fb} alt={getClientData('clientCompanyName') + " on Facebook"}/><p>{getClientData('facebook')}</p></a>
				</div>
				<div>
					<a href={'https://instagram.com/' + getClientData('instagram')}><img src={insta} alt={getClientData('clientCompanyName') + " on Instagram"}/><p>{'@'+getClientData('instagram')}</p></a>
				</div>
				</div>
			</div>
			<div style={{width: "100%", height: '50px'}}>

			</div>
			<div style={{width: "100%", height: '70px', textAlign: 'center'}}>
				<p style={{width: "100%", height: '25px', textAlign: 'center'}}>Copyright 2023</p>
				<p style={{width: "100%", height: '25px', textAlign: 'center'}}><a href='https://laskowski.no'>Webdesign Laskowski Creative</a></p>
			</div>
			<div style={{width: "100%", height: '50px'}}>

</div>
		</div>
		) 
	}
}

export default Footer;