import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { producent, perks, technologies, dedicated } from "./../solars";


class DescriptionBoxHeader extends Component {
    constructor () {
        super();
        this.state = {
        }
        this.fullProductCardActivate = this.fullProductCardActivate.bind(this);
    }

    fullProductCardActivate(event){
        const productId = event.target.getAttribute('name');
        this.props.fullCardActivate(productId);


    }
    render (){ 

    const header = this.props.header ? this.props.header : ''; 
    return (
        <div>
            <h1 className="header-link" name={this.props.index} onClick={this.fullProductCardActivate}>{header}</h1>
        </div>
    )} 
}


export default DescriptionBoxHeader;