import React, { Component } from "react";
import {Container, Row, Col, Form, Button } from 'react-bootstrap';
import './snowflakes.css';

class SnowFlakes extends Component {
  constructor () {
    super();
	  this.state = {
    }
  } 
          


 render ()
  {
    return (
    <div className="snowflakes" aria-hidden="true">
        <div className="snowflake">
        ❅
        </div> 
        <div className="snowflake">
        ❅
        </div>
        <div className="snowflake">
        ❆
        </div>
        <div className="snowflake">
        ❄
        </div>
        <div className="snowflake">
        ❅
        </div>
        <div className="snowflake">
        ❆
        </div>
        <div className="snowflake">
        ❄
        </div>
        <div className="snowflake">
        ❅
        </div>
        <div className="snowflake">
        ❆
        </div>
        <div className="snowflake">
        ❄
        </div>
    </div>
    
    );
  }
}

export default SnowFlakes;
