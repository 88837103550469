import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import DescriptionBoxAboutTechnology from "./DescriptionBoxAboutTechnology";
import DescriptionBoxBrief from "./DescriptionBoxBrief";
import DescriptionBoxGallery from "./DescriptionBoxGallery";
import DescriptionBoxHeader from "./DescriptionBoxHeader";
import DescriptionBoxNavigation from "./DescriptionBoxNavigation";
import DescriptionBoxPerks from "./DescriptionBoxPerks";
import DescriptionBoxTechnicalData from "./DescriptionBoxTechnicalData";
import DescriptionBoxDedication from "./DescriptionBoxDedication";
import DescriptionBoxDocuments from "./DescriptionBoxDocuments";
import { producent, perks, technologies, dedicated } from "./../solars";
import newRibbon from './../new.png';
import cart from './../cart.png';

class DescriptionBox extends Component {
    constructor () {
        super();
        this.state = {
            selected: 'brief',
            hovered: '',
            imgs: []
      }
      this.hovered = this.hovered.bind(this);
      this.selected = this.selected.bind(this);
    }

	hovered(value) {
        this.setState({
            hovered: value,
        });
      }	
      
      selected(value) {
        this.setState({
            selected: value,
        });
      }
      


    render (){      
    const item = this.props.item;
    const index = this.props.index;

    return (
        <Row key={index} id={item.folder} className='content'>
            <Col id='navigation-dots' lg={{span: 1, order: 1}} md={{span: 12, order: 2}} sm={{span: 12, order: 2}} xs={{span: 12, order: 2}}>
                <div className='navigation-dots'>
                    {window.innerWidth < 800 && <DescriptionBoxHeader fullCardActivate={this.props.fullCardActivate} header={item.type[0]} index={index}/>}
                    <DescriptionBoxNavigation selectedNow={this.state.selected} hoveredNow={this.state.hovered} selected={this.selected} hovered={this.hovered}/>
                </div>
                <div className="description-background"></div>
            </Col>
            {<Col lg={{span: 5, order: 2}} md={{span: 12, order: 4}} sm={{span: 12, order: 4}} xs={{span: 12, order: 4}}>
                <div className="description">
                   {window.innerWidth > 800 && <DescriptionBoxHeader fullCardActivate={this.props.fullCardActivate} header={item.type[0]} index={index}/>}
                    {(((this.state.selected === 'brief' && this.state.hovered === '' ) || this.state.hovered === 'brief'))  && window.innerWidth < 800 && 
                        <div className="dedicated-section">
                            <DescriptionBoxDedication dedicated={item.dedicated}/>
                        </div>
                    }
                    {((this.state.selected === 'brief' && this.state.hovered === '' ) || this.state.hovered === 'brief')  && <DescriptionBoxBrief recommended={item.recommended} folder={item.folder} imgs={item.imgs} efficiency={item.efficiency} technologies={item.technologies} powerClasses={item.powerClasses} />}
                    {((this.state.selected === 'technicalData' && this.state.hovered === '' ) || this.state.hovered === 'technicalData')  && <DescriptionBoxTechnicalData technicalData={item}/>}
                    {((this.state.selected === 'aboutTechnology' && this.state.hovered === '' ) || this.state.hovered === 'aboutTechnology')  && <DescriptionBoxAboutTechnology efficiency={item.efficiency} aboutTechnology={item.technologies}/>}
                    {((this.state.selected === 'perks' && this.state.hovered === '' ) || this.state.hovered === 'perks')  && <DescriptionBoxPerks/>}
                    {((this.state.selected === 'documents' && this.state.hovered === '' ) || this.state.hovered === 'documents')  && <DescriptionBoxDocuments folder={item.folder} dataSheet={item.dataSheet} />}
                    {((this.state.selected === 'gallery' && this.state.hovered === '' ) || this.state.hovered === 'gallery')  && <DescriptionBoxGallery recommended={item.recommended} folder={item.folder} imgs={item.imgs} />}
                    
                    <div className="description-background"></div>
                </div>
            </Col>}

            {window.innerWidth > 800 && <Col lg={{span: 1, order: 3}} md={{span: 12, order: 3}} sm={{span: 12, order: 3}} xs={{span: 12, order: 3}}>
                <div className="dedicated-section">
                    <DescriptionBoxDedication dedicated={item.dedicated}/>
                </div>
                <div className="description-background"></div>
            </Col>}
            {<Col className="picture-big" lg={{span: 5, order: 4}} md={{span: 12, order: 1}} sm={{span: 12, order: 1}} xs={{span: 12, order: 1}}>
                <div style={{position: 'absolute', top: '-12vh', left: '-1.5vw', zIndex: 3, backgroundImage: "url("+newRibbon+")",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0% 0%',
                    backgroundSize: '100%',
                    width: '40%',
                    height: '100%',
                    filter: 'hue-rotate(66deg) brightness(1.5)',
                    visibility: item.new ? 'visible' : 'hidden',
                }}/>
            <div className='picture'>
                <DescriptionBoxGallery recommended={item.recommended} folder={item.folder} imgs={item.imgs} />
                 <div className="description-background"></div>
         
                </div> 
            </Col>}
            {/*<Col lg={{span: 1, order: 5}} md={{span: 12, order: 5}} sm={{span: 12, order: 5}} xs={{span: 12, order: 5}}>
                <div className='navigation-dots'>
                    <div style={{zIndex: '3', backgroundImage: 'url('+cart+')', width: '50px', height: '50px', transform: 'rotateY(180deg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0% 0%',
                    backgroundSize: '100%',
                    filter: 'invert(0.3) hue-rotate(9deg) brightness(1.8)'}}/>
                </div>
                <div className="description-background"></div>
            </Col>*/}
        </Row>
    )} 
}


export default DescriptionBox;